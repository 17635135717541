import { createI18n } from 'vue-i18n';
import en from './en';
import fr from './fr';
import ja from './ja';
import pt from './pt';
import es from './es';
import { CommonUtility } from '@/script/utility';
import { routerPageURL, goRouterPage } from '@/routes';

let langs = [
    'en', 'fr', 'ja', 'es', 'pt',
];
let lang = 'en';
let lastPath = CommonUtility.getUrlRelativeLastComponentPath().toLowerCase();
if (langs.indexOf(lastPath) != -1) {
    lang = lastPath;
}
export const i18n = createI18n({
    locale: lang,
    messages: {
        en,
        fr,
        ja,
        es,
        pt,
    },
});


export default {
    install: (app) => {
        app.use(i18n)
        app.config.globalProperties.i18n = i18n
        app.config.globalProperties.$jumpOtherPage = jumpOtherPage;
        app.config.globalProperties.$otherPageUrl = otherPageUrl;
    },
};

/**
 * 跳转页面
 * @param {页面key} page 
 * @returns 
 */
function jumpOtherPage (page) {
    return goRouterPage(page, i18n.global.locale);
}
/**
 * 获取页面URL
 * @param {页面key} page 
 * @returns 
 */
function otherPageUrl (page) {
    return routerPageURL(page, i18n.global.locale)
}