export default {
  siteName: 'PixelHaha',
  creation: 'AI Image',
  product: 'Product',
  magicBrush: 'Magic Brush',
  pricing: 'Pricing',
  freeCredits: 'Free Credits',
  credits: 'Credits',
  getMoreCredits: 'Get More Credits',
  left: 'Left',
  upgradeToPremium: 'Upgrade to Premium',
  go: 'Go!',
  accountInformation: 'Account Information',
  signOut: 'Sign Out',
  getMore: 'Get More',
  contactSupport: 'Contact Support',
  history: 'History',
  login: 'Log in',
  signup: 'Sign up',
  aiFuture: 'AI Feature',
  aIImageGenerator: 'AI Image Generator',
  imageEnhacner: 'AI Image Enhancer',
  videoEnhacner: 'AI Video Enhancer',
  language: 'Language',
  support: 'Support',
  userPolicy: 'User Policy',
  privacyPolicy: 'Privacy Policy',
  refundPolicy: 'Refund Policy',
  blog: 'Blog',
  helpAndFAQS: 'Help & FAQS',
  siteMap: 'SiteMap',
  sitemapXMLUrl: 'SiteMap XML',
  bussiness: 'Bussiness',
  affiliateProgram: 'Affiliate Program',
  api: 'API',
  contactUs: 'Contact Us',
  details: 'Details  >',
  chooseFace: 'Choose Face',
  tryAgain: 'Try Again',

  choose: {
    delText: 'Are you sure to delete this face?',
    leftBtnText: 'Continue',
    rightBtnText: 'Cancel',
    overFaces: 'Uploading faces have reached limit, please delete other faces to continue.',
    faceAn: 'Face analyzing...',
    facesuccess: 'Face analysis success',
    facefailed: 'Face analysis Failed',
    feedback: 'Thanks for your feedback!',
    copy: 'Copy successful!',
    batchsupportAIGirl: 'Batch Supported! You can make new AI Image no matter the current result.',
    submitting: 'Submitting',
    queuing: 'Queuing',
    creating: 'Creating',
    downloading: 'Downloading',
    retryText: 'Something went wrong, please try again later.',
    retry: 'Retry',
    emailTip: 'Please enter a valid domain name suffix (such as .com, .net, etc.)',
    noaccount: 'No account yet? ',
    enterPassWord: 'Enter Password',
    passWordErr: 'Set password 8 characters minimum',
    loginPassWordErr: 'Password must be at least 8 characters',
    forgotpass: 'Forgot password?',
    googlesign: 'Login with Google Sign-In?',
    resetpassword: 'Reset Password',
    readyaccount: 'Already have an account?',
    confirm: 'Confirm',
    resetpasswordsuccess: 'Reset Password Success!',
    senderror: 'Send Error!',
    sendsuccess: 'Send Success!',
    signupfree: 'Sign Up for Free ',
    signupsuccess: 'Sign Up Success!',
    sendcode: 'Send Code',
    loginsuccess: 'Log in successfully!',
    logingerror: 'Login Error!',
    signuperror: 'Sign up failed!',
    aboutS: 'about {leftTime}s',
    keepPatient: 'Keep patient!',
    needMoretime: 'Need more time!',
    aimostDoneKeep: 'Almost done! Keep patient!',
    usernamePlace: "user{'@'}example.com",
    emailCode: 'Verification code',
  },
  feedbackDialog: {
    title: 'We need your feedback to improve PixelHaha.',
    subTitle1: 'Want to improve?',
    option1: 'Inaccurate Face analysis about uploaded files',
    option2: 'Not looks like',
    option3: 'Face detail issues( Distorted, eyes, mouth etc.)',
    option4: 'Slow Time',
    option5: 'Result Sharpness',
    txtOption1: 'Sensitive or disturbing content',
    txtOption2: 'Violation of terms of service',
    txtOption3: 'Prompt Relevance',
    txtOption4: 'Face Distorted',
    txtOption5: 'Image Content',
    txtOption6: 'Image Sharpness',
    txtOption7: 'Slow Time',
    histOption1: 'Poor results',
    histOption2: 'Image Sharpness',
    histOption3: 'Slow Time',
    histOption4: 'Image Content',
    subTitle2: 'Want to say more?',
    placeholder: 'Other suggestions...',
    submit: 'Submit',
  },
  infoDialog: {
    advancedFeatures: 'Advanced Features',
    choosePlan: 'Choose Plan',
    insufficientCredits: 'Insufficient Credits',
    insufficientCreditsText: 'Your credit balance:',
    getMoreCredits: 'Get more Credits',
    videoTooBig: 'Video is too big',
    videoTooLong: 'Video is too long',
    videoCannotExceed: 'Video cannot exceed',
    inFileSize: 'in file size',
    selectAnotherFile: 'Select another file',
    photoTooBig: 'Photo is too big',
    photoCannotExceed: 'Photo cannot exceed',
    tenMin: '10Min',
    deleteFile: 'Are you sure to delete this file?',
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  allRightsReserved: 'All rights reserved.',
  aiGirlIntroductionDetails: 'AI Image Introduction Details',
  aiGirlHowToUse: 'AI Image How to Use',
  discoverHot: "Discover Trending Searches",
  aiGirlFAQDetails: 'AI Image FAQ Details',
  lookslikeUploadOpps: {
    title: 'Oops!',
    subtitle: 'Oops! Exceed free credits limits!\nPlease subscribe plan to use all features unlimitedly.',
    unlockLimit: 'Unlock limit',
  },
  selectStyle: 'Select Style',
  purchasePopup: {
    title: 'Successfully!',
    consumerSuccess: "You've earned <xx1> credits",
    subscribeSuccess: 'Unlimited  Access! Enjoy our service!',
    ok: 'OK',
  },
  affiliatePopup: {
    title: 'Affiliate Program Tuturial',
    first: 'First',
    second: 'Second',
    linkHint: 'Affiliate Program Link:',
    copy: 'Copy',
    ok: 'OK',
  },
}
