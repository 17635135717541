const devConfig = {
    version: '1.3.0',
    packageName: 'bns.web.pix',
    apiHost: 'https://testweb-pix-api.test876.com',
    mediaCDN: "https://gg-cdn-pixelhaha.test876.com",
    googleSignInClientId : '175957342235-leceu9jr33g1975nkq8rp6siaitff1d4.apps.googleusercontent.com',
    kodepay: {
        applicationID: '3ad1f408-1357-11ef-8f5e-626fe6505cda',
        clientID: 'a4fcf11a-7758-11ef-ab9b-dab2a2aae00b'
    },
    firebaseConfig: {
        apiKey: 'AIzaSyB_5y0JFZLBqO1HzdvAi-H2Mg_ILez4mMc',
        appId: '1:175957342235:web:beda6360860942485020be',
        messagingSenderId: '175957342235',
        projectId: 'fangyi-f2f7f',
        authDomain: 'fangyi-f2f7f.firebaseapp.com',
        storageBucket: 'fangyi-f2f7f.appspot.com',
        measurementId: 'G-TY9G8V457K',
    },
};


const prodConfig = {
    version: '1.3.0',
    packageName: 'ai.pixelhaha.web',
    apiHost: 'https://webapi.pixelhaha.ai',
    mediaCDN: "https://file-cdn.pixelhaha.ai",
    googleSignInClientId: '822325685418-o1m5cgiugkprc37fibr1u0f433eh3m11.apps.googleusercontent.com',
    kodepay: {
        applicationID: 'f0e31724-5a45-11ef-bd6b-7644c550dc99',
        clientID: '82f685ac-7723-11ef-812b-928f62f479bc'
    },
    firebaseConfig : {
        apiKey: 'AIzaSyAoGglvAqgD_N3_AVkJXzOvv_p4wXaFsR0',
        appId: '1:822325685418:web:0568148fa916895a7cf4d3',
        messagingSenderId: '822325685418',
        projectId: 'pixelhaha-eea78',
        authDomain: 'pixelhaha-eea78.firebaseapp.com',
        storageBucket: 'pixelhaha-eea78.appspot.com',
        measurementId: 'G-26L4QH670F',
    },
};

export const ENV = {
    get currentConfig () {
        if (this.isProd) {
            return prodConfig;
        }
        return devConfig;
    },
    get isDev () {
        return process.env.VUE_APP_ENV == 'dev';
    },
    get isUat () {
        return process.env.VUE_APP_ENV == 'uat';
    },
    get isProd () {
        return !this.isDev && !this.isUat;
    },
};